<template>
  <div class="card">
    <a-page-header
      :style="'border: 1px solid rgb(235, 237, 240);'"
      :title="activity.designation"
      :sub-title="`Service:${activityName} | Nombre élèves: ${activityFees.length}`"
      id="header-id"
      class="card-header"
      @back="() => $router.go(-1)"
    >
      <div class="row">
        <div class="col-md-4"><b>Order: </b>{{ activity.order }}</div>
        <div class="col-md-4">
          <b>{{ $t("all.startDate") }}: </b
          >{{ moment(activity.start).format("DD-MM-YYYY") }}
        </div>
        <div class="col-md-4">
          <b>{{ $t("all.endDate") }}: </b
          >{{
            activity.start ? moment(activity.end).format("DD-MM-YYYY") : "-"
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <b>{{ $t("recette.fraisInscri") }}: </b>{{ activity.inscription }}DT
        </div>
        <div class="col-md-4">
          <b>{{ $t("recette.fraisMensuel") }}: </b
          >{{ activity.once ? "Non" : activity.monthly + "DT" }}
        </div>
        <div class="col-md-4"></div>
      </div>
      <div class="row mt-5">
        <div class="col-md-4" v-if="!activity.once">
          <a-select
            id="selectMonth"
            :placeholder="$t('paiement.selectionnerMois')"
            style="width: 100%"
            @change="
              (val) => {
                handleFilterChangeV2('month', val);
              }
            "
          >
            <a-select-option value="all"> Tous les mois </a-select-option>
            <a-select-option
              v-for="(m, index) in monthsArr"
              :key="index + 1"
              :value="index + 1"
            >
              {{ m }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-md-4">
          <a-select
            id="selectClass"
            show-search
            :placeholder="$t('emploi.listeClasses')"
            option-filter-prop="children"
            :filter-option="filterOption"
            style="width: 100%"
            @change="
              (val) => {
                handleFilterChangeV2('classe', val);
              }
            "
          >
            <a-select-option value="all"> Tous les classes </a-select-option>
            <a-select-option
              v-for="classe in classList"
              :key="classe._id"
              :value="classe._id"
            >
              {{ classe.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-md-4" v-if="!activity.once">
          <a-button type="file-pdf" @click="generatePdf" style="width: 100%">
            <a-icon type="file-pdf" @click="generatePdf" />
            {{ $t("action.imprimerTab") }}
          </a-button>
        </div>
        <div class="col-md-4 offset-md-4" v-else>
          <a-button type="file-pdf" @click="generatePdf" style="width: 100%">
            <a-icon type="file-pdf" @click="generatePdf" />
            {{ $t("action.imprimerTab") }}
          </a-button>
        </div>
      </div>
    </a-page-header>

    <div class="card-body">
      <a-table
        :data-source="activeDataTable"
        :loading="tableLeading"
        :columns="columns"
        :pagination="true"
        rowKey="_id"
      >
        <template slot="expandedRowRender" slot-scope="record">
          <div style="margin: 0">
            <div v-for="(fee, key) in record.fees" :key="key" class="d-inline">
              <a-tag :color="fee.isPayed ? 'green' : 'red'">
                <b v-if="fee.isPayed"
                  >{{ formatFeeName(fee.feeName) }} {{ $t("paiement.paye") }}</b
                >
                <b v-else
                  >{{ formatFeeName(fee.feeName) }}
                  {{ $t("paiement.impaye") }}</b
                >
              </a-tag>
            </div>
          </div>
        </template>
        <template slot="student" slot-scope="text, record">
          <router-link
            :to="`/eleve/${record.studentId}`"
            class="kit__utils__link"
            >{{ record.student }}</router-link
          >
        </template>
        <template slot="mode" slot-scope="text">
          <a-tag color="#3498db" v-if="text == 'M'"> Paiement mensuelle </a-tag>
          <a-tag color="#27ae60" v-else> Paiement par tranche </a-tag>
        </template>
        <template slot="status" slot-scope="text, record">
          <a-tag :color="record.status == 'payed' ? 'green' : 'red'">
            <b v-if="record.status == 'payed'">{{ $t("paiement.paye") }}</b>
            <b v-else>{{ $t("paiement.impaye") }}</b>
          </a-tag>
        </template>
        <template slot="classroom" slot-scope="text, record">
          {{ record.classroom.name }}
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import { PageHeader, Descriptions } from "ant-design-vue";
import moment from "moment";
import { mapState } from "vuex";
import "jspdf-autotable";
import { formatFeeName } from "@/utilities/settings";
import { pdfHeader } from "@/utilities/pdfUtils";

const getStatus = (fees) => {
  if (fees.find((f) => !f.isPayed)) return "impayed";
  else {
    return "payed";
  }
};
const columns = [
  {
    title: "Nom de l'élève",
    dataIndex: "student",
    key: "student",
    scopedSlots: {
      customRender: "student",
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
    },
  },
  {
    title: "Classe",
    dataIndex: "classroom",
    key: "classroom",
    scopedSlots: {
      customRender: "classroom",
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
    },
  },
  {
    title: "Parents",
    dataIndex: "parent",
    key: "parent",
    scopedSlots: {
      customRender: "parent",
    },
  },
  {
    title: "Téléphones des parents",
    dataIndex: "parentPhones",
    key: "parentPhones",
    scopedSlots: {
      customRender: "parentPhones",
    },
  },
  {
    title: "Mode de paiement",
    dataIndex: "mode",
    key: "mode",
    width: "150px",
    scopedSlots: {
      customRender: "mode",
    },
    filters: [
      { text: "Mensuelle", value: "M" },
      { text: "Par tranche", value: "T" },
    ],

    onFilter: (value, record) => record.mode.indexOf(value) === 0,
  },
  {
    title: "État global",
    dataIndex: "status",
    key: "status",
    scopedSlots: {
      customRender: "status",
    },
    filters: [
      { text: "Payé", value: "payed" },
      { text: "Non payé", value: "impayed" },
    ],
    onFilter: (value, record) => record.status.indexOf(value) === 0,
  },
];
export default {
  computed: mapState(["settings"]),
  components: {
    "a-page-header": PageHeader,
    "a-descriptions": Descriptions,
  },
  async created() {
    this.monthsArr = moment.months();

    this.tableLeading = true;
    this.form = this.$form.createForm(this, { name: "addActivityForm" });
    const classPromise = apiClient
      .post("/classrooms/filter", {
        query: {
          status: "active",
        },
        aggregation: {
          $project: {
            _id: 1,
            name: 1,
          },
        },
      })
      .then((res) => (this.classList = res.data))
      .catch((e) => this.$message.error("Aucunne classe est trouvé"));
    const ActivityPromise = apiClient
      .post("/activities/filter", {
        query: {
          _id: this.activityId,
        },
        aggregation: [
          {
            $lookup: {
              from: "services",
              localField: "service",
              foreignField: "_id",
              as: "service",
            },
          },
          {
            $project: {
              once: 1,
              service: { name: 1 },
              inscription: 1,
              monthly: 1,
              start: 1,
              end: 1,
              designation: 1,
              order: 1,
            },
          },
        ],
      })
      .then((res) => (this.activity = res.data[0]))
      .catch((e) => this.$message.error("Activité non trouvé!"));

    await Promise.all([classPromise, ActivityPromise]);
    this.activityName = this.activity.service[0].name;
    apiClient
      .get("/payment/etatImpaye/activity/" + this.activityId)
      .then((res) => {
        this.activityFees = res.data.map((p) => ({
          ...p,
          classroom:
            this.classList.find((c) => c._id == p.classroom)?.name ?? "--",
          status: getStatus(p.fees),
        }));
      })
      .catch((e) => {
        console.log(e);
        this.$message.error("Aucun inscription au activité est trouvé: ");
      })
      .finally(() => {
        this.activeDataTable = this.activityFees;
        this.tableLeading = false;
      });
  },
  data() {
    return {
      monthsArr: [],
      columns,
      tableLeading: false,
      activityName: "",
      activity: {},
      activeDataTable: [],
      activityFees: [],
      classList: [],
      filters: {
        classe: false,
        classe_val: null,
        month: false,
        month_val: null,
      },
    };
  },
  props: {
    activityId: {
      required: true,
    },
  },
  methods: {
    moment,
    formatFeeName,
    handleFilterChangeV2(on, value) {
      const other = on == "classe" ? "month" : "classe";
      let newData = [];
      if (value == "all") {
        this.filters[on] = false;
        newData = this.activityFees;
      } else {
        this.filters[on] = true;
        this.filters[`${on}_val`] = value;
        if (on == "classe")
          newData = this.activityFees.filter(
            (elem) => elem.classroom._id == value
          );
        else {
          newData = [...this.activityFees]
            .filter((elem) => !elem.once)
            .map((elem) => {
              const fees = [...elem.fees];
              let newFees = this.filterFees(elem, fees, value);
              return {
                ...elem,
                fees: newFees,
                status: getStatus(newFees),
              };
            })
            .filter((e) => e.fees.length > 0);
        }
      }
      if (this.filters[other]) {
        if (other == "classe")
          newData = [...newData].filter(
            (elem) => elem.classroom._id == this.filters[`${other}_val`]
          );
        else {
          newData = [...newData]
            .filter((elem) => !elem.once)
            .map((elem) => {
              const fees = [...elem.fees];
              let newFees = this.filterFees(
                elem,
                fees,
                this.filters[`${other}_val`]
              );
              return {
                ...elem,
                fees: newFees,
                status: getStatus(newFees),
              };
            })
            .filter((e) => e.fees.length > 0);
        }
      }
      this.activeDataTable = newData;
    },
    filterFees(elem, fees, value) {
      return fees.filter((fee) => {
        if (fee.feeName == "inscription") return false;
        if (elem.mode == "M") {
          if (parseInt(fee.feeName.split("-")[1]) == parseInt(value))
            return true;
        } else {
          //mode is par tranche
          let trancheMonths = elem.tranchesFee.find(
            (t) => t.trancheNumber == fee.feeName
          ).months;
          if (trancheMonths.includes(parseInt(value))) return true;
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    generatePdf() {
      this.$gtag.event("Imp tab etatPaiementActivite", {
        event_category: "Impression PDF",
        event_label: "activites - etatPaiement section",
        value: 1,
      });
      var SelectedMonth = "";
      var SelectedClass = "";
      try {
        SelectedMonth = document.getElementById("selectMonth").innerText;
        SelectedClass = document.getElementById("selectClass").innerText;
      } catch (error) {}
      let title = this.filters.month
        ? `Etat des paiements pour le mois ${SelectedMonth}`
        : `Etat des paiements pour tous les mois`;
      let subTitle = this.filters.classe
        ? "Etat des paiements - " +
          SelectedClass +
          ", " +
          this.activity.designation
        : "Etat des paiements - " + this.activity.designation;
      const doc = pdfHeader(this.settings, null, title, subTitle);
      var arr = [];
      const array = [];
      var counter = 1;
      let colSpan = 0;
      for (let paymentRow of this.activeDataTable) {
        let currentIndex = 0;
        colSpan = 0;

        if (paymentRow.fees) {
          colSpan += paymentRow.fees.length;
        }
        if (currentIndex === 0) {
          currentIndex++;
          arr.push({
            rowSpan: colSpan,
            content: counter,
            styles: { valign: "middle" },
          });
          arr.push({
            rowSpan: colSpan,
            content: paymentRow.student,
            styles: { valign: "middle" },
          });
          /*arr.push({rowSpan: colSpan,
              content: paymentRow.activity,
              styles: { valign: 'middle' }});*/
          arr.push({
            rowSpan: colSpan,
            content: paymentRow.parent,
            styles: { valign: "middle" },
          });
          arr.push({
            rowSpan: colSpan,
            content: paymentRow.parentPhones || "--",
            styles: { valign: "middle" },
          });
          counter++;
        }

        for (let fee of paymentRow.fees) {
          let feeName = formatFeeName(fee.feeName);
          arr.push(feeName);
          fee.isPayed
            ? arr.push({
                content: "payé",
                styles: { textColor: [14, 115, 31], fontStyle: "bold" },
              })
            : arr.push({
                content: "impayé",
                styles: { textColor: [209, 23, 45], fontStyle: "bold" },
              });
          array.push(arr);
          arr = [];
        }
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        head: [["Num", "Nom complet", "Parent", "Numéro", "Frais", "Etat"]],
        body: array,
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 15 : 38,
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Etat_paiements_activité " + this.activityName + ".pdf");
    },
  },
};
</script>
